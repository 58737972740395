import React from 'react'

import BreadCrumb from '../components/breadCrumb/index'
import Layout from '../components/layout'
import SEO from '../components/seo'


const crumbs = [
  {
    depth: '1',
    url: '/',
    title: 'Home',
    styles: '',
  },
]

const ProcessPage = () => {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Process`, `Double Diamond`]}
        title="Process"
      />
      <BreadCrumb crumbs={crumbs} />
      <section className="flex flex-col md:flex-row items-center">
        <section id="about-summary" className="mb-8">
          <h2>Process</h2>
          <h3>
            The design process I follow
          </h3>
          <p className="mb-5">
            Throughout my career, I have tried and tested many approaches to delivering quality experiences for users. However, I have found the 'double diamond' process (developed by the British Design Council) to be the most effective. With it's use of divergent and convergent thinking applied at the appropriate moments in the process, it helps solve real problems that have come from user needs. 
          </p>
          <div class="xl:max-w-2xl">
            <h3 class="font-bold text-3xl">
              Solving problems
            </h3>
            <p>
              Through experience, I have learnt that the most innovative ideas (that really solve a user needs) come from solving problems, rather than simply implementing pre-existing solutions.
            </p>
            <p class="font-bold">Designing a solution to a problem vs re-designing an existing solution</p>
            <p>When teaching this design process, I often ask student or colleagues to complete a quick design task. Initially, I ask: 'Design a doorbell.' The designs that are produced are always pretty much the same, just styled differently.</p>
            <p>I then ask: 'Design a way to let a user know there is someone at the door'. The designs are often vastly different from each other and coontain ideas never seen before. This is because they were given a problem to solve through design, rather than a solution to redesign.</p>
            <p class="mb-5">I take the same approach when working as a UX designer in any industry.</p>
            <h3 class="font-bold text-3xl">The double diamond process</h3>
            <p>The double diamond</p>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default ProcessPage
